







































































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import { Hit, Scrap, scraps } from "~/config/algolia"

export type PreciseSearchModel = {
  adUrl: string
  ocularId: string
  adId: string
  date: string
  text: string
  scrapNames: string[]
  showDeactivatedHouses: boolean
  showOnlyDeactivatedHouses: boolean
  photoCount: number
  searchInDeals: boolean
}

@Component({})
export default class PreciseSearch extends Vue {
  @Prop({ required: true }) value!: PreciseSearchModel
  searchScraps: Hit<Scrap>[] = []
  searchByScrap = this.value.scrapNames.join(" ")
  openDateMenu = false

  @Watch("value.showDeactivatedHouses")
  resetvalueOnlyDeactivatedHouses() {
    if (this.value.showDeactivatedHouses === false)
      this.value.showOnlyDeactivatedHouses = false
  }

  @Watch("searchByScrap")
  async onChangeInput(value = "") {
    if (this.searchScraps.length == 0 && this.value.scrapNames.length != 0) {
      for (let scrapName in this.value.scrapNames) {
        const result = await scraps.search(scrapName)
        this.searchScraps = result.hits as Hit<Scrap>[]
      }
    }
    const result = await scraps.search(value)
    this.searchScraps = result.hits as Hit<Scrap>[]
  }

  get dateFormatted() {
    return this.value.date
      ? this.formatDate(new Date(this.value.date).toISOString().substr(0, 10))
      : null
  }
  formatDate(date: string) {
    if (!date) return null
    const [year, month, day] = date.split("-")
    return `${day}/${month}/${year}`
  }
  parseDate(date: string) {
    if (!date) return null
    const [month, day, year] = date.split("/")
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
  }
}
